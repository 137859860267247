import React, { useState, useEffect } from "react";
import Hamburger from "hamburger-react";
import Logo from "../asset/images/logo.png";
import menu from "../asset/menu.svg";
import close from "../asset/close.svg";
import { navLinks } from "../constants";
import "../asset/css/Navbar.css"; // Import the CSS file for Main Navbar styles

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 830);
  const [isOpen, setOpen] = useState(false);

  const handleClick = (nav, event) => {
    event.preventDefault();
    if (nav.id === "home") {
      smoothScrollTo(0); // Scroll to the top of the page
    } else if (nav.id) {
      // Prevent default anchor behavior
      setActive(nav.title);
      if (!toggle) setToggle(true); // Close mobile menu when a nav item is clicked
      const element = document.getElementById(nav.id);
      if (element) {
        const yOffset = -80; // Adjust offset as per your design
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        smoothScrollTo(y);
      }
    }
  };

  const handleLogoClick = () => {
    smoothScrollTo(0); // Scroll to the top of the page
  };

  const smoothScrollTo = (target) => {
    const startPosition = window.pageYOffset;
    const distance = target - startPosition;
    const duration = 500; // Adjust the duration as needed
    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      window.scrollTo(
        0,
        easeInOutCubic(progress, startPosition, distance, duration)
      );
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  };

  const easeInOutCubic = (t, b, c, d) => {
    if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b;
    return (c / 2) * ((t -= 2) * t * t + 2) + b;
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 830);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsTop(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav
      className={`main-navbar ${isTop ? "main-navbar-top" : "main-navbar-not"}`}
    >
      {/* Logo */}
      <img
        src={Logo}
        alt="hoobank"
        className="logo"
        onClick={handleLogoClick}
      />

      {/* Desktop Navigation */}
      {!isMobile && (
        <ul className="desktop-nav">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id || nav.path || index}
              className={`nav-item ${
                index === navLinks.length - 1 ? "last-item" : ""
              }`}
              onClick={(event) => handleClick(nav, event)} // Pass event object here
            >
              {nav.path ? (
                <a href={nav.path}>{nav.title}</a>
              ) : (
                <a href={`#${nav.id}`}>{nav.title}</a>
              )}
            </li>
          ))}
        </ul>
      )}
      {/* Mobile Navigation */}
      {isMobile && (
        <div className="hamburger-icon">
          {isMobile && (
            <div className="hamburger-icon">
              <Hamburger
                className="hamburger"
                toggled={isOpen}
                toggle={setOpen}
              />
              <div className={`mobile-menu ${isOpen ? "show" : ""}`}>
                {" "}
                {/* Updated className */}
                {/* Hamburger Icon */}
                <ul>
                  {navLinks.map((nav, index) => (
                    <li
                      key={nav.id}
                      className={`mobile-nav-item ${
                        active === nav.title ? "active" : ""
                      }`}
                      onClick={(event) => handleClick(nav, event)} // Pass event object here
                    >
                      <a href={`#${nav.id}`}>{nav.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
