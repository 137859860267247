import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box, Typography, useTheme } from "@mui/material";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;
  const theme = useTheme(); // Get access to the current theme

  return (
    <Box component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={3}
            sx={{ ml: { md: "auto" }, mb: { xs: 3, md: 0 } }}
          >
            <Box>
              <Link to={brand.route}>
                <Box
                  component="img"
                  src={brand.image}
                  alt={brand.name}
                  maxWidth="10rem"
                  mb={2}
                />
              </Link>
              <Typography variant="h6">{brand.name}</Typography>
            </Box>
            <Box
              display={{ xs: "none", md: "flex" }}
              alignItems="center"
              mt={3}
            >
              {socials.map(({ icon, link }, key) => (
                <Typography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                  sx={{ textDecoration: "none" }}
                >
                  {icon}
                </Typography>
              ))}
            </Box>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <Typography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </Typography>
              <Box component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <Box key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <Typography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        sx={{
                          textDecoration: "none",
                          color: theme.palette.text.primary,
                        }}
                      >
                        {name}
                      </Typography>
                    ) : (
                      <Typography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        sx={{
                          textDecoration: "none",
                          color: theme.palette.text.primary,
                        }}
                      >
                        {name}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

DefaultFooter.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  ).isRequired,
};

export default DefaultFooter;
  