import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid,
} from "@mui/material";

function TourCard({ tour, recommend }) {
  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
      <Card className="tour-card" sx={{ borderRadius: 3, maxWidth: "430px" }}>
        <CardMedia
          component="img"
          height="300px"
          image={tour.image}
          alt={tour.title}
          sx={{ objectFit: "cover" }}
        />
        <CardContent>
          <Grid
            items
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <div>
              <Typography
                variant="h5"
                component="div"
                sx={{ fontFamily: "Fjalla One", paddingBottom: "10px" }}
              >
                {tour.title}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontFamily: "Fjalla One", paddingBottom: "10px" }}
              >
                {tour.description}
              </Typography>
              {tour.promotions && !recommend ? (
                <>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{ fontFamily: "Fjalla One", paddingBottom: "10px" }}
                  >
                    Price:{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {tour.price}
                    </span>{" "}
                    <span
                      style={{
                        color: "red",
                        fontFamily: "Fjalla One",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {tour.promotions}{" "}
                    </span>
                    Bath
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontFamily: "Fjalla One", paddingBottom: "10px" }}
                >
                  Price: {tour.price} Bath
                </Typography>
              )}
            </div>
            <div style={{ width: "fit-content" }}>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  fontFamily: "Fjalla One",
                  textAlign: "center",
                  px: "10px",
                }}
                size="large"
              >
                Book
              </Button>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default TourCard;
