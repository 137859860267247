import React, { useState, useEffect } from "react";
import { Card, Typography, Grid } from "@mui/material";
import bgImage1 from "../../asset/images/products/2.jpg";
import bgImage2 from "../../asset/images/products/5.jpg";
import bgImage3 from "../../asset/images/products/6.jpg";
import bgImage4 from "../../asset/images/products/20.jpg";
import bgImage5 from "../../asset/images/products/19.jpg";

function AboutMe() {
  const backgroundImages = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5];
  const [currentBgIndex, setCurrentBgIndex] = useState(
    Math.floor(Math.random() * backgroundImages.length)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImages.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="about">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={6} sm={12}>
          <Card
            className="about-me-card"
            sx={{
              backgroundImage: `url(${backgroundImages[currentBgIndex]})`,
            }}
          ></Card>
        </Grid>
        <Grid item xs={12} md={6} sm={12} sx={{ textAlign: "left" }}>
          <div className="about-me-content">
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontFamily: "Fjalla One",
                fontSize: { xs: "40px", md: "60px" },
                fontWeight: 100,
                padding: "0 2rem",
                pt: "2rem",
              }}
            >
              About Me
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontFamily: "Fjalla One",
                fontWeight: 100,
                fontSize: { xs: "20px", md: "30px" },
                padding: "1rem 2rem",
              }}
            >
              Get warm with our journey. Get warm with our journey. Get warm
              with our journey. Get warm with our journey. Get warm with our
              journey. Get warm with our journey. Get warm with our journey.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AboutMe;
