import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  TextField,
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";

function Employee() {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [country, setCountry] = useState("");
  const [position, setPosition] = useState("");
  const [wage, setWage] = useState("");
  const [employeeList, setEmployeeList] = useState([]);

  // Function to fetch employee list
  const getEmployee = () => {
    Axios.get("https://demo-deploy.com/Hugmeontour/api/read")
      .then((response) => {
        setEmployeeList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
      });
  };

  const addEmployee = (event) => {
    event.preventDefault();
    Axios.post("https://demo-deploy.com/Hugmeontour/api/create", {
      name: name,
      age: age,
      country: country,
      position: position,
      wage: wage,
    })
      .then(() => {
        getEmployee(); // Call getEmployee after successful addition
        // Reset form fields after successful addition
        setName("");
        setAge("");
        setCountry("");
        setPosition("");
        setWage("");
      })
      .catch((error) => {
        console.error("Error adding employee:", error);
      });
  };

  const deleteEmployee = (id) => {
    Axios.delete(`https://demo-deploy.com/Hugmeontour/api/delete/${id}`)
      .then(() => {
        getEmployee(); // Call getEmployee after successful deletion
      })
      .catch((error) => {
        console.error("Error deleting employee:", error);
      });
  };

  // Call getEmployee function when component mounts
  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <Container maxWidth="md" style={{ marginTop: "100px" }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Employee Information
      </Typography>
      <form onSubmit={addEmployee}>
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          id="age"
          label="Age"
          variant="outlined"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          id="country"
          label="Country"
          variant="outlined"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          id="position"
          label="Position"
          variant="outlined"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          id="wage"
          label="Wage"
          variant="outlined"
          value={wage}
          onChange={(e) => setWage(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" type="submit">
          Add Employee
        </Button>
      </form>
      <hr />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {employeeList.map((employee) => (
          <Card
            key={employee.id}
            style={{ minWidth: "250px", maxWidth: "300px" }}
          >
            <CardContent>
              <Typography variant="h5" component="h2">
                {employee.name}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Age: {employee.age}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Country: {employee.country}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Position: {employee.position}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Wage: {employee.wage}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="warning"
                onClick={() => deleteEmployee(employee.id)}
              >
                Delete
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    </Container>
  );
}

export default Employee;
