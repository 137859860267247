import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import bgImage from "../../asset/images/bg-coworking.jpeg"; // Replace with your image path

const useStyles = makeStyles(() => ({
  hero: {
    position: "relative",
    width: "100%",
    backgroundImage: `url(${bgImage})`,
    // backgroundSize: "cover",
    backgroundPosition: "top",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#000", // Access default text color
    marginTop: 0,
  },
  heroContent: {
    zIndex: 1, // Ensure content is displayed over the image
  },
}));

function Landing() {
  const classes = useStyles();
  const theme = useTheme(); // Access the current theme

  return (
    <>
      <Box
        id="home"
        className={classes.hero}
        sx={{
          backgroundSize: { xs: "none", sm: "cover", md: "cover", lg: "cover" },
          minHeight: theme.breakpoints.down("xs") ? "40vh" : "30vh",
          [theme.breakpoints.up("sm")]: {
            minHeight: "30vh",
          },
          [theme.breakpoints.up("md")]: {
            minHeight: "60vh",
          },
        }}
      >
        <Box className={classes.heroContent}>
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontFamily: "Fjalla One",
                fontSize: theme.typography.pxToRem(100),
                [theme.breakpoints.down("md")]: {
                  fontSize: theme.typography.pxToRem(70), // Adjust font size for smaller screens
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: theme.typography.pxToRem(50), // Adjust font size for smaller screens
                },
              }}
            >
              Hug Me On Tour
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontFamily: "Fjalla One",
                fontSize: theme.typography.pxToRem(50),
                [theme.breakpoints.down("md")]: {
                  fontSize: theme.typography.pxToRem(30), // Adjust font size for smaller screens
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: theme.typography.pxToRem(20), // Adjust font size for smaller screens
                },
              }}
            >
              Get warm with our journey.
            </Typography>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default Landing;

{
  /* <Grid
  container
  justifyContent={"space-between"}
  alignItems="center"
  spacing={1}
>
  <Button
    variant="contained"
    color="warning"
    sx={{
      mx: 3,
      mb: 2,
      bgcolor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      // Set size using theme spacing
      padding: theme.spacing(1), // Adjust as needed
      fontSize: theme.typography.pxToRem(16),
      // Or set size using theme breakpoints
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.typography.pxToRem(10),
        padding: theme.spacing(0.5), // Adjust for small screens
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(1), // Adjust for small screens
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(14),
        padding: theme.spacing(1), // Adjust for medium and larger screens
      },
    }}
  >
    Promotions
  </Button>
  <Typography variant="h3" gutterBottom></Typography>
</Grid>; */
}
