import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import TourCard from "../../components/Card";

function Promotions() {
  const theme = useTheme(); // Access the current theme

  const tours = [
    {
      id: 1,
      title: "Doi Suthep",
      image:
        "https://guide2thailand.com/wp-content/uploads/2020/01/Wat-Phra-That-Doi-Suthep-in-Chiang-Mai-Thailand.jpg",
      description: "Visit Wat Phra That Doi Suthep",
      price: 2000,
      promotions: 1500,
    },
    {
      id: 2,
      title: "Silver Temple",
      image:
        "https://livingnomads.com/wp-content/uploads/2017/06/28/Wat-Sri-Suphan-and-the-Silver-Ubosot-Chiang-Mai-3.jpg",
      description: "Visit Wat Sri Suphan Temple",
      price: 2000,
      promotions: 1200,
    },
    {
      id: 3,
      title: "Doi Inthanon ",
      image:
        "https://www.thainationalparks.com/img/poi/2019/12/10/394801/two-chedis-w-900.jpg",
      description: "Visit King & Queen Pagodas",
      price: 2000,
      promotions: 1800,
    },
  ];

  return (
    <div id="promotions">
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontFamily: "Fjalla One",
          fontSize: { xs: "40px", md: "60px" },
          fontWeight: 100,
          textAlign: "center",
          mt: theme.breakpoints.up("lg") ? "200px" : "100px",
          pb: theme.breakpoints.up("lg") ? "50px" : "20px",
          [theme.breakpoints.down("md")]: {
            mt: "100px",
            pb: "20px",
          },
        }}
      >
        Promotions
      </Typography>
      <Grid container spacing={2}>
        {tours.map((tour) => (
          <Grid item xs={12} sm={6} md={4} key={tour.id}>
            <TourCard tour={tour} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Promotions;
