import React from "react";
import { ThemeProvider } from "@mui/material/styles"; // Import ThemeProvider
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import footerRoutes from "./footer.routes";
import customTheme from "./themes";
import "./asset/css/AboutMe.css";
import "./asset/css/tourCard.css";
import "./asset/css/Navbar.css";

import { useTheme } from "@mui/material/styles";

import { AboutMe, Landing, Promotion, Recommend } from "./pages";
// import AboutMe from "./components/about";
// import Promotion from './components/promotions';
import { Box } from "@mui/material";
import Employee from "./pages/test/formEmployees";

function App() {
  const theme = useTheme();
  return (
    <ThemeProvider theme={customTheme}>
      {" "}
      {/* Wrap your entire application with ThemeProvider */}
      <div className="App">
        <Navbar />
        <Landing />
        <Box
          sx={{
            boxShadow: theme.shadows[1], // Utilize theme shadow
            borderRadius: theme.shape.borderRadius, // Utilize theme border radius
            p: theme.spacing(2), // Utilize theme spacing
            mx: { xs: theme.spacing(2), lg: theme.spacing(5) }, // Utilize theme spacing with breakpoints
            mt: { xs: -6, sm: -7, md: -7, lg: -7 },
            mb: theme.spacing(4),
            px: {
              xs: theme.spacing(5),
              sm: theme.spacing(5),
              md: theme.spacing(7),
              lg: theme.spacing(7),
            },
            backgroundColor: "rgba(255, 255, 255, 0.4)", // Hardcoded color with transparency
            backdropFilter: "saturate(200%) blur(30px)",
          }}
        >
          <AboutMe />
          <div style={{ margin: "0 5px" }}>
            <Promotion />
            <Recommend />
            {/* <Comment /> */}
          </div>
          <div style={{ marginBottom: "50px" }}>
            <Employee />
          </div>
          <Footer content={footerRoutes} />
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
